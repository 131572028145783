<template>
  <div>
    <vl-modal-template>
      <template v-slot:header>
        <vl-modal-header title="Genereer template(s)">
          <span class="text-14p font-600 text-blue-dark cursor-pointer select-none"
                @click="$store.dispatch('DossierDetailModule/setDisplayGenerateTemplate', false)">

                    <span class="inline-block mr-4p">
            <font-awesome-icon icon="times"/>
          </span>
            Sluiten
          </span>
        </vl-modal-header>
      </template>

      <template>
        <section>
          <vl-display-template :dossier="Dossier"/>
        </section>
      </template>

      <template v-slot:footer></template>

    </vl-modal-template>
  </div>
</template>

<script>
import VlModalTemplate from "@/components/Elements/Modal/Elements/VlModalTemplate";
import VlModalHeader from "@/components/Elements/Modal/Elements/VlModalHeader";
import VlDisplayTemplate from '@/components/Elements/Modal/Template/VlDisplayTemplate'

export default {
  name: "VlGenerateTemplateModal",
  components: {
    VlModalTemplate,
    VlModalHeader,
    VlDisplayTemplate
  },
  computed: {
    Dossier() {
      return {
        contact: {
          owner: this.$store.state.DossierDetailModule.detail.contact.owner
        },
        address: this.$store.state.DossierDetailModule.detail.address,
        log: {
          actions: this.$store.state.DossierDetailModule.detail.log.actions
        },
        measures: this.$store.state.DossierDetailModule.detail.measures,
        examination: this.$store.state.DossierDetailModule.detail.examination
      }
    }
  }
}
</script>

<style scoped>

</style>
