<template>
<div>
  <transition
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
      :css="false"
  mode="out-in">
    <slot></slot>
  </transition>
</div>
</template>

<script>
import {gsap} from "gsap";
export default {
name: "SlideDownAnimation",
  methods: {
    beforeEnter(el) {
      gsap.set(el, {opacity: 0,clearProps: 'all'})
    },
    enter(el, done) {
      gsap.from(el, {opacity: 0,y: -500, duration: .3, clearProps: 'all', onComplete: done})
    },
    leave(el, done) {
      gsap.to(el, {opacity: 0,y: -500,  duration: .3, clearProps: 'all', onComplete: done})
    }
  }
}
</script>

<style scoped>

</style>
