<template>
  <vl-fade-animation>
    <div class="shadow bg-blue-dark rounded p-4 text-white grid items-center cursor-pointer gap-12p" v-if="visible"
         style="grid-template-columns: 36px 1fr 15px" @click="visible = false">
      <div class="h-36p w-36p">
        <img :src="require('@/assets/images/info.svg')">
      </div>
      <div class="font-700 text-sm">
        {{ text }}
      </div>
      <div class="">
        <font-awesome-icon icon="times"/>
      </div>
    </div>
  </vl-fade-animation>
</template>

<script>
import VlFadeAnimation from "@/components/Animations/VlFadeAnimation";

export default {
  props: [
    'text',
    'icon'
  ],
  components: {
    VlFadeAnimation
  },
  data() {
    return {
      visible: true
    }
  }
}
</script>
