<template>
  <header class="flex justify-between" @click="toggle">
    <div>
      <vl-card-title :title="title" :amount="amount"/>
    </div>
    <div>
      <div>
        <font-awesome-icon icon="chevron-up" class="transition duration-300"  :class="{'fa-rotate-180' :value , 'fa-rotate-270': !value}"/>
      </div>
    </div>
  </header>
</template>

<script>
import VlCardTitle from "@/components/Elements/Card/VlCardTitle";

export default {
  name: "VlCardHeader",
  components: {
    VlCardTitle
  },
  props: {
    title: {
      type: String,
      required: true
    },
    amount: {
      required: false,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style scoped>

</style>
