<template>
  <div>
    <vl-card>
      <vl-card-header title="Interne documenten" :amount="Attachments.length" v-model="showData" class="z-10"/>
      <section class="bg-white relative z-10" v-show="!$store.getters['DossierDetailModule/isArchived']">
        <vl-add-file class="m-2 mb-4"></vl-add-file>
        <hr class="border-gray-border-30 h-2"/>
      </section>

      <section class="py-8p" v-show="!HasAttachments">
        <vl-not-available-notification message="Er zijn geen interne documenten beschikbaar"/>
      </section>

      <div class="overflow-auto max-h-500p">
            <slide-down-animation>
              <section v-show="showData" >
                <div :key="`attachment_${attachment.id}`" v-for="attachment in Attachments" class="grid">
                  <vl-file
                            :filename="attachment.document"
                           :filetype="attachment.extension"
                           :download_url="attachment.url"/>
                </div>
              </section>
            </slide-down-animation>
      </div>

    </vl-card>
  </div>
</template>


<script>
import VlCard from "@/components/Elements/Card/VlCard";
import VlCardHeader from "@/components/Elements/Card/VlCardHeader";
import VlFile from '@/components/Elements/VlFile';
import VlAddFile from '@/components/Elements/VlAddFile';
import VlNotAvailableNotification from "@/components/Elements/VlNotAvailableNotification";
import SlideDownAnimation from '@/components/Animations/SlideDownAnimation'

export default {
  name: "VlInternalDocuments",
  components: {
    VlCard,
    VlCardHeader,
    VlFile,
    VlAddFile,
    VlNotAvailableNotification,
    SlideDownAnimation
  },
  computed: {
    HasAttachments() {
      return this.Attachments.length > 0;
    },

    Attachments() {
      return this.$store.getters['DossierDetailModule/getDossierAttachments']
    }
  },
  data() {
    return {
      showData: false
    }
  }
}
</script>

<style scoped>

</style>
