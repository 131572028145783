<template>
  <div class="vl-file grid grid-cols-min-1fr border-b border-solid border-gray-border-30 pb-10p mb-10p pr-10p">
    <div class="mr-4 w-35p lg:w-40p">
      <svg viewBox="0 0 109 134" version="1.1" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
           style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <path
            d="M83.333,33.333c-4.625,0 -8.333,-3.75 -8.333,-8.333l0,-16.667l25,25l-16.667,0Zm16.667,83.334c0,4.583 -3.75,8.333 -8.333,8.333l-75,-0c-4.625,-0 -8.334,-3.75 -8.334,-8.333l0,-100c0,-4.625 3.709,-8.334 8.334,-8.334l49.875,0c-0.084,9.959 0.083,16.667 0.083,16.667c0,9.167 7.458,16.667 16.667,16.667l16.666,-0l0,75l0.042,-0Zm-25,-116.709l0,0.125c-0.542,0 -2.792,-0.208 -8.333,-0.125l-50,0c-9.209,0 -16.667,7.459 -16.667,16.667l0,100c0,9.167 7.458,16.667 16.667,16.667l75,-0c9.166,-0 16.666,-7.5 16.666,-16.667l0,-83.333l-33.333,-33.334Z"
            style="fill:#194670;stroke:#194670;stroke-width:4.17px;"/>
        <text x="29.167px" y="83.333px"
              style="font-family:'ArialMT', 'Arial', sans-serif;font-size:29.084px;fill:#194670;">.{{ FileType }}</text>
      </svg>


    </div>
    <div class="">
      <div class="overflow-x-hidden">
        <p class="text-14p">{{ FileName }}</p>
      </div>
      <div class="flex justify-end">
        <div @click="downloadFile" class="cursor-pointer">

          <div v-if="!is_downloading">
            <span class="text-blue-light">
              <span class="inline-block w-16p">
                <img :src="require('@/assets/images/download.svg')" alt="">
              </span>
            Download
          </span>
          </div>
          <div v-if="is_downloading">
            <span class="inline-block mr-4p text-blue-light"><font-awesome-icon icon="circle-notch"
                                                                                class="fa-spin"></font-awesome-icon></span><span
              class="text-blue-light">Downloaden</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as APIService from '@/services/ApiService'
import {ToastMixin} from "@/mixins/ToastMixin";
import {ProcessFilesMixin} from "@/mixins/ProcessFiles.mixin";

export default {
  name: 'VlFile',
  props: [
    'filename',
    'filetype',
    'download_url',
  ],
  mixins: [ToastMixin, ProcessFilesMixin],
  data() {
    return {
      is_downloading: false
    }
  },
  computed: {
    FileType() {
      return this.filetype
    },
    FileName() {
      return this.filename
    },
    downloadLink() {
      return this.download_url
    }
  },
  methods: {
    downloadFile() {
      this.is_downloading = true
      APIService
          .downloadFile(this.download_url)
          .then(response => {
            this.processFile(response.data.content, response.data.filename, response.data.extension)
          })
          .catch(() => {
            this.throwErrorMessage('Er is iets misgelopen bij het ophalen van dit bestand. Gelieve contact op te nemen indien dit blijft aanhouden.')
          }).finally(() => this.is_downloading = false)
    }
  }
}
</script>

<style lang="scss">
.vl-file:last-of-type{
  @apply border-none;
}
</style>
