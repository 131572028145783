<template>
  <div>
    <vl-modal-template>
      <template v-slot:header>
        <vl-modal-header title="Nieuwe actie toevoegen"/>
      </template>

      <template>
        <section class="grid grid-cols-1 xl:grid-cols-2 gap-20p">
          <section>
            <select-action-type v-model.number="action_id"/>
            <select-execution-date v-model="executed_at"/>
            <select-deadline v-model="deadline"/>
          </section>

          <section>
            <comment v-model="comment"/>
            <add-files v-model="documents"/>
          </section>
        </section>
      </template>

      <template v-slot:footer>
        <div class="grid grid-cols-min-min justify-end">
          <div class="mr-8p self-center">
            <span class="text-14p font-600 text-blue-light cursor-pointer select-none"
                  @click="closeModal">Annuleren</span>
          </div>
          <div class="whitespace-nowrap self-center">


            <div class="px-16p py-8p bg-blue-light hover:bg-blue-dark-hover text-white rounded-4p cursor-pointer" @click="addAction">
              <div v-show="!isUploading">
                Actie toevoegen aan logboek
              </div>
              <div v-show="isUploading">
                <span><font-awesome-icon icon="circle-notch" class="fa-spin"/></span> Actie aan het toevoegen
              </div>
            </div>
          </div>
        </div>

      </template>

    </vl-modal-template>
  </div>
</template>

<script>
import ModalElements from "@/components/Elements/Modal/Elements/ModalElements";

import * as APIService from '@/services/ApiService'
import {ToastMixin} from "@/mixins/ToastMixin";
import CreateAction from "@/services/models/CreateAction";

export default {
  name: "VlAddActionModal",
  components: {
    VlModalTemplate: ModalElements.VlModalTemplate,
    VlModalHeader: ModalElements.VlModalHeader,
    SelectActionType: ModalElements.VlModalSelectActionType,
    SelectDeadline: ModalElements.VlModalSelectDeadline,
    SelectExecutionDate: ModalElements.VlModalExecutedDate,
    Comment: ModalElements.VlModalComment,
    AddFiles: ModalElements.VlModalAddFiles,
  },
  data() {
    return {
      action_id: null,
      executed_at: new Date(),
      deadline: null,
      comment: '',
      documents: [],
      isUploading: false
    }
  },
  mixins: [ToastMixin],
  methods: {
    closeModal() {
      this.$store.dispatch('DossierDetailModule/setDisplayAddAction', false)
    },
    addAction() {
      if (this.isUploading) return

      const createAction = new CreateAction({
        action_id: this.action_id,
        executed_at: this.executed_at,
        deadline: this.deadline,
        comment: this.comment
      })

      if (createAction.isValid()) {
        this.isUploading = true

        APIService
            .postLogbookItem(createAction.preparePost(), this.$store.state.DossierDetailModule.dossier_id)
            .then((response) => {
              const promiseArray = this.processDocuments(response.data.id)

              Promise
                  .all(promiseArray)
                  .then(() => {
                    this.$store.dispatch('DossierDetailModule/fetchDossierData')
                        .then(() => {
                          this.clearData()
                          this.closeModal()
                        })
                  })

            })
      } else {
        this.throwWarningMessage('Gelieve deze actie na te kijken')
      }
    },
    processDocuments(id) {
      const promiseArray = []
      if (this.documents.length === 0) return promiseArray

      this.documents.forEach(item => {
        promiseArray.push(new Promise(resolve => {
          const formData = new FormData()
          formData.append('file', item)

          APIService
              .storeFile(formData, id)
              .then(() => resolve())
        }))
      })

      return promiseArray
    },
    clearData() {
      this.action_id = 3640366
      this.executed_at = new Date()
      this.deadline = null
      this.comment = ''
      this.documents = []
      this.isUploading = false
    }
  },
  created() {
    this.clearData()

    this.action_id = this.$store.state.DataModule.actions[0].id
  }
}
</script>

<style scoped>

</style>
