<template>
  <div>
    <vl-modal-template>
      <template v-slot:header>
        <vl-modal-header :title="`Dossiernummer: ${Code}`">
          <span class="text-14p font-600 text-blue-dark cursor-pointer select-none"
                @click="$store.dispatch('DossierDetailModule/DossierDetailInspectionModule/setDisplayInspection', false)">
                    <span class="inline-block mr-4p">
            <font-awesome-icon icon="times"/>
          </span>
            Sluiten
          </span>
        </vl-modal-header>
      </template>

      <template>
        <div class="mb-20p grid gap-20p grid-cols-3">
          <div class="whitespace-nowrap">
            <div><span class="text-12p text-blue-dark 2xl:text-14p select-none">Dossiernummer</span></div>
            <div><span class="text-14p 2xl:text-16p select-none">{{ Code | nonEmpty }}</span></div>
          </div>

          <div class="whitespace-nowrap">
            <div><span class="text-12p text-blue-dark 2xl:text-14p select-none">Resultaat keuring</span></div>
            <div class="grid grid-cols-min-min gap-4p">
              <div class="pt-4p inline-block place-self-center w-16p">
                <span class="inline-block circle-left" :class="LeftCircleStyle"/>
                <span class="inline-block circle-right" :class="RightCircleStyle"/>
              </div>
              <div class="self-center">
                <span class="text-14p 2xl:text-16p select-none">{{ Result | nonEmpty }}</span>
              </div>
            </div>
          </div>

          <div class="whitespace-nowrap">
            <div><span class="text-12p text-blue-dark 2xl:text-14p select-none">Type keuring</span></div>
            <div><span class="text-14p 2xl:text-16p select-none">{{ Type | nonEmpty }}</span></div>
          </div>

        </div>


        <div class="mb-20p">
          <vl-label label="Keuringsdocumenten"/>
          <div :key="`attachment_${attachment.id}`" v-for="attachment in Attachments"
               class="flex max-h-500p overflow-y-auto">
            <vl-modal-action-attachment :attachment="attachment"/>
          </div>
          <div v-if="Attachments.length === 0">
            <vl-not-available-notification message="Er zijn geen documenten beschikbaar"/>
          </div>
        </div>

        <div class="grid grid-cols-1 gap-20p">
          <div class="pb-10p mb-10p">
            <vl-label label="Herstelmaatregelen"/>
            <div :key="`measures_detail_${index}`" v-for="(item, index) in Measures">
              <vl-measures-item :code="item.code" :chapter="item.chapter" :description="item.name"></vl-measures-item>
            </div>
            <div v-if="Measures.length === 0">
              <vl-not-available-notification message="Er zijn geen herstelmaatregelingen beschikbaar"/>
            </div>
          </div>
          <div>
            <vl-label label="Aandachtspunten"/>
            <div :key="`poi_detail_${index}`" v-for="(item, index) in POI"
                 class="border-b border-solid border-gray-border-30 pb-10p mb-10p">
              <vl-poi-item :code="item.code" :chapter="item.chapter" :description="item.name"/>
            </div>
            <div v-if="POI.length === 0">
              <vl-not-available-notification message="Er zijn geen aandachtspunten beschikbaar"/>
            </div>
          </div>

        </div>

      </template>

      <template v-slot:footer></template>

    </vl-modal-template>

  </div>
</template>

<script>
import VlLabel from '@/components/Elements/Form/Elements/VlLabel'
import ModalElements from "@/components/Elements/Modal/Elements/ModalElements";
import VlNotAvailableNotification from '@/components/Elements/VlNotAvailableNotification'
import VlPoiItem from "@/components/Elements/VlPoiItem";
import VlMeasuresItem from "@/components/Elements/VlMeasuresItem";

export default {
  name: "VlShowInspectionModal",
  components: {
    VlModalTemplate: ModalElements.VlModalTemplate,
    VlModalHeader: ModalElements.VlModalHeader,
    VlLabel,
    VlModalActionAttachment: ModalElements.VlModalActionAttachment,
    VlNotAvailableNotification,
    VlPoiItem,
    VlMeasuresItem
  },
  computed: {
    LeftCircleStyle() {
      if (this.Result === 'NC' || this.Result === 'NCO') return 'bg-red'
      if (this.Result === 'CO' || this.Result === 'C') return 'bg-green'
      return ''
    },
    RightCircleStyle() {
      if (this.Type === 'NC') return 'bg-red'
      if (this.Result === 'NCO' || this.Result === 'CO') return 'bg-orange'
      if (this.Result === 'C') return 'bg-green'
      return ''
    },

    Type() {
      return this.$store.state.DossierDetailModule.DossierDetailInspectionModule.inspection.type
    },
    Code() {
      return this.$store.state.DossierDetailModule.DossierDetailInspectionModule.inspection.code
    },
    Result() {
      return this.$store.state.DossierDetailModule.DossierDetailInspectionModule.inspection.result
    },
    Attachments() {
      return this.$store.state.DossierDetailModule.DossierDetailInspectionModule.inspection.attachments
    },
    Measures() {
      return this.$store.state.DossierDetailModule.DossierDetailInspectionModule.inspection.measures
    },
    POI() {
      return this.$store.state.DossierDetailModule.DossierDetailInspectionModule.inspection.attention
    }
  }
}
</script>

<style scoped>

</style>
