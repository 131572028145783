<template>
  <div>
    <vl-card>
      <vl-card-title title="Aandachtspunten" :amount="Attention.length"/>
      <section v-show="showData">
        <vl-poi-item :key="`point_of_intrest_${index}`" :description="poi.name" :chapter="poi.chapter" :code="poi.code" v-for="(poi, index) in Attention"/>

        <div class="text-14p text-blue-light text-center" v-if="Attention.length === 0">Er zijn geen aandachtspunten beschikbaar</div>
      </section>
    </vl-card>
  </div>
</template>

<script>
import VlCard from "@/components/Elements/Card/VlCard";
import VlPoiItem from "@/components/Elements/VlPoiItem";
import VlCardTitle from "@/components/Elements/Card/VlCardTitle";

export default {
  name: "VlPoi",
  components: {
    VlCard,
    VlPoiItem,
    VlCardTitle
  },
  data() {
    return {
      showData: true
    }
  },
  computed:{
    Attention(){
      return this.$store.getters['DossierDetailModule/getDossierAttention']
    }
  }
}
</script>

<style scoped>

</style>
