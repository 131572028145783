<template>
  <div>
    <label for="add-internal-document-input" class="grid grid-cols-min-1fr relative cursor-pointer">
      <div>
        <div class="mr-4 w-8 h-8 bg-blue-dark hover:bg-blue-dark-hover grid grid-cols-1 grid-rows-1 rounded-4p">
          <div class="place-self-center text-white" v-if="!is_uploading">
            <font-awesome-icon icon="plus"/>
          </div>

          <div class="place-self-center text-white" v-if="is_uploading">
            <font-awesome-icon icon="circle-notch" class="fa-spin"/>
          </div>

        </div>
      </div>

      <div class="self-center">
        <span class="text-blue-light" v-if="!is_uploading">Voeg een nieuw document toe</span>
        <span class="text-blue-light" v-if="is_uploading">Documenten aan het toevoegen</span>
        <input type="file" id="add-internal-document-input" class="absolute opacity-0 w-1" @change="addFiles" multiple>
      </div>

    </label>
  </div>
</template>

<script>
import {ToastMixin} from "@/mixins/ToastMixin";
import {UploadFilesMixin} from "@/mixins/UploadFiles.mixin";
import * as ApiService from "@/services/ApiService";

export default {
  mixins: [
      ToastMixin,
    UploadFilesMixin
  ],
  computed: {
    DossierID() {
      return parseInt(this.$store.getters['DossierDetailModule/getDossierId'])
    }
  },
  data() {
    return {
      is_uploading: false
    }
  },
  methods: {
    addFiles(event) {
      if (!this.is_uploading) {
        this.is_uploading = true

        const fileList = event.target.files;
        if (fileList.length === 0) return
        const files = this.getFilesFromList(fileList)
        const promiseArray = this.createPromiseArray(files, this.DossierID)

        Promise
            .all(promiseArray)
            .then(() => {
              ApiService
                  .getDossierDetails(this.DossierID)
                  .then((response) => {
                    const attachments = response.data.attachments

                    this.$store.dispatch('DossierDetailModule/updateDossierAttachments', attachments)
                        .then(() => {
                      this.throwSuccessMessage('Documenten zijn toegevoegd')
                      event.target.value = ''
                    })
                  })
            }).finally(() => this.is_uploading = false)
      }
    }
  }
}
</script>
