<template>
  <div class="px-2 grid grid-cols-logbook-grid bg-gray-light-20 py-8p mb-12p rounded-4p">
    <div class="self-center flex">
      <span class="text-blue-light text-12p whitespace-nowrap select-none">
        Deadline
      </span>
    </div>
    <div class="self-center flex">
      <span class="text-blue-light text-12p whitespace-nowrap select-none">
        Actie
      </span>
    </div>
    <div class="self-center flex">
      <span class="text-blue-light text-12p whitespace-nowrap select-none">
        Uitvoerder
      </span>
    </div>
    <div class="self-center flex">
      <span class="text-blue-light text-12p whitespace-nowrap select-none">
        Opmerkingen
      </span>
    </div>
    <div class="self-center flex">
      <span class="text-blue-light text-12p whitespace-nowrap select-none"></span>
    </div>
    <div class="self-center"></div>
  </div>
</template>

<script>
export default {
  name: "VlLogTableHeader",
}
</script>
