<template>
  <div class="examination-table-data-container">
    <div v-if="isLoading">
      <div class="text-14p text-blue-light text-center">
        <font-awesome-icon icon="circle-notch" class="fa-spin"/>
        We zijn de data aan het ophalen
      </div>
    </div>


    <div class="examination-table-data" @click="showInspection" v-if="!isLoading">
      <vl-index-dossier-card-element label="KPR nummer" :content="KPRNumber | nonEmpty" :flex="true"/>
      <vl-index-dossier-card-element label="Resultaat keuring" :flex="true">

        <div class="grid grid-cols-min-min gap-4p">
          <div class="pt-4p inline-block place-self-center w-16p">
            <span class="inline-block circle-left" :class="LeftCircleStyle"/>
            <span class="inline-block circle-right" :class="RightCircleStyle"/>
          </div>
          <div class="text-gray-light text-14p self-center">
            {{ ExaminationResult | nonEmpty }}
          </div>
        </div>
      </vl-index-dossier-card-element>
      <vl-index-dossier-card-element label="Type keuring" :content="ExaminationType | nonEmpty" :flex="true"/>

      <div class="hidden lg:block place-self-center"><span class="text-blue-light text-14p"><font-awesome-icon
          icon="chevron-right"/></span></div>
    </div>


  </div>


</template>

<script>
import VlIndexDossierCardElement from "@/components/Elements/VlIndexDossierCardElement";
import * as APIService from '@/services/ApiService'

export default {
  name: "VlExaminationTableData",
  components: {
    VlIndexDossierCardElement,

  },
  props: {
    examination: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      examination_details: null
    }
  },
  computed: {
    LeftCircleStyle() {
      if (this.ExaminationResult === 'NC' || this.ExaminationResult === 'NCO') return 'bg-red'
      if (this.ExaminationResult === 'CO' || this.ExaminationResult === 'C') return 'bg-green'
      return ''
    },
    RightCircleStyle() {
      if (this.ExaminationResult === 'NC') return 'bg-red'
      if (this.ExaminationResult === 'NCO' || this.ExaminationResult === 'CO') return 'bg-orange'
      if (this.ExaminationResult === 'C') return 'bg-green'
      return ''
    },
    KPRNumber() {
      return this.examination_details.code
    },
    ExaminationType() {
      return this.examination_details.type
    },
    ExaminationResult() {
      return this.examination_details.result
    }
  },
  methods: {
    showInspection() {
      this.$store.dispatch('DossierDetailModule/DossierDetailInspectionModule/setInspection', this.examination_details)
    }
  },
  created() {
    this.isLoading = true
      APIService
          .getInspectionDetails(this.examination.id)
          .then(response => {
            let type = ''

            if (response.data.inspection_type_id) {
              type = this.$store.getters['DataModule/getTypeById'](response.data.inspection_type_id)
            }


            this.examination_details = {...response.data, type: type}
            this.isLoading = false
          })

  }
}
</script>

<style lang="scss">
.examination-table-data-container{
  @apply bg-white;
  @apply border-b;
  @apply border-solid;
  @apply border-gray-light-30;
  @apply pb-10p;
  @apply mb-10p;


  &:last-of-type {
    @apply border-none;
  }

  @screen lg {
    @apply border-none;
    @apply mb-0;
    @apply py-10p;
    @apply px-2;

    &:nth-child(odd) {
      @apply bg-gray-light-10;
    }
    &:hover {
      @apply bg-gray-light-30;
    }
  }

}

.examination-table-data {
  @screen lg {
    @apply grid;
    @apply gap-8p;
    grid-template-columns: 150px 150px 1fr  50px;



  }
}
</style>

