<template>
<div>
  <vl-card>
    <vl-card-title title="Nieuwe keuringen"/>

    <div class="hidden lg:block">
      <vl-examination-table-header class="w-full"/>
    </div>

    <div v-if="HasExaminations">
      <vl-examination-table-data v-for="(examination) in Examinations" :key="`new_examinations_${examination.id}`"
                                 :examination="examination" />
    </div>

    <div class="text-14p text-blue-light text-center" v-if="!HasExaminations">Er zijn geen nieuwe keuringen beschikbaar</div>
  </vl-card>
</div>
</template>

<script>
import VlCard from "@/components/Elements/Card/VlCard";
import VlCardTitle from "@/components/Elements/Card/VlCardTitle";
import VlExaminationTableHeader from "@/components/Details/Elements/VlExaminationTableHeader";
import VlExaminationTableData from "@/components/Details/Elements/VlExaminationTableData";

export default {
name: "VlNewExaminations",
  components: {
    VlCard,
    VlCardTitle,
    VlExaminationTableHeader,
    VlExaminationTableData
  },
  computed:{
    HasExaminations() {
      return this.Examinations.length > 0
    },
  Examinations(){
    return this.$store.getters['DossierDetailModule/getDossierNewExaminations']
  }
  }
}
</script>

<style scoped>

</style>
