<template>
  <div>
    <vl-modal-template>
      <template v-slot:header>
        <vl-modal-header :title="Action">
          <span class="text-14p font-600 text-blue-dark hover:text-blue-dark-hover cursor-pointer select-none"
                @click="$store.dispatch('DossierDetailModule/setDisplayLogEntry', false)">
          <span class="inline-block mr-4p">
            <font-awesome-icon icon="times"/>
          </span>
            Sluiten</span>
        </vl-modal-header>
      </template>

      <template>
        <div class="border-b border-solid border-gray-border pb-10p mb-10p">
          <vl-label label="Gegevens"/>
          <div class="grid gap-8p pb-20p"
               style="grid-template-columns: 120px 290px 1fr 160px">
            <vl-modal-display-data label="Uitvoeringsdatum" :value="ExecutedAt | nonEmpty"/>
            <vl-modal-display-data label="Actie" :value="Action | nonEmpty"/>
            <vl-modal-display-data label="Uitvoerder" :value="ExecutedBy | nonEmpty"/>

            <div>
              <div><span class="text-12p text-gray-light 2xl:text-14p select-none">Deadline</span></div>
              <div v-if="!showDeadlineUpdate">
                <div>
                  <span class="text-14p 2xl:text-16p select-none">{{ Deadline | nonEmpty }} <span
                    class="text-14p text-blue-dark cursor-pointer"
                    @click="showDeadlineUpdate = true"
                    v-show="!$store.getters['DossierDetailModule/isArchived']"><font-awesome-icon
                    icon="edit"/></span>
                  </span>
                </div>
              </div>

              <div v-if="showDeadlineUpdate">
                <div class="grid gap-4p" style="grid-template-columns: 120px 1fr">
                  <div style="width: 120px">
                    <vl-date-picker-small v-model="deadline" :clearable="false"/>
                  </div>
                  <button class="bg-blue-light hover:bg-blue-light-hover rounded-4p grid w-25p h-25p place-self-center"
                          @click="saveNewDeadline">
                    <span class="place-self-center text-white text-12p"><font-awesome-icon icon="check"/></span>
                  </button>
                </div>
              </div>


            </div>
          </div>

          <div class="pb-20p">
            <vl-modal-display-data label="Opmerking" :value="Comment | nonEmpty"/>
          </div>
        </div>

        <div class="border-b border-solid border-gray-border pb-10p mb-10p">
          <vl-label label="Wens je bijlagen toe te voegen"/>
          <div class="grid grid-cols-min-1fr mt-10p">
            <div v-show="!$store.getters['DossierDetailModule/isArchived']">
              <label for="add-document-input" class="grid grid-cols-min-1fr relative cursor-pointer">
                <div >
                  <div
                      class="mr-4 w-8 h-8 bg-blue-dark transition-colors duration-150 hover:bg-blue-dark-hover grid grid-cols-1 grid-rows-1 rounded-4p">
                    <div class="place-self-center text-white" v-show="!isUploading">
                      <font-awesome-icon icon="plus"/>
                    </div>
                    <div class="place-self-center text-white" v-show="isUploading">
                      <font-awesome-icon icon="circle-notch" class="fa-spin"/>
                    </div>
                  </div>
                </div>
                <div class="self-center">
                  <input type="file" id="add-document-input" class="absolute opacity-0" @change="addFiles" multiple>
                </div>

              </label>
            </div>
            <div class="max-h-300p overflow-y-auto">

              <div :key="`attachment_${attachment.id}`" v-for="attachment in Attachments"
                   class="flex ">
                <vl-modal-action-attachment :attachment="attachment"/>
              </div>
            </div>

          </div>
        </div>

        <div>
          <vl-label label="Opvolging"/>

          <div :key="`commenting_participants_${index}`" v-for="(participant,index) in CommentingParticipants"
               class="mt-10p max-h-300p overflow-y-auto">
            <vl-modal-participant
                :participant="participant"
                @remove="removeComment(participant)"
            />
          </div>

          <div class="flex mt-10p" v-show="!$store.getters['DossierDetailModule/isArchived']">
            <vl-textfield v-model="comment" class="w-full mr-6p"/>
            <vl-button classes="bg-blue-dark hover:bg-blue-dark-hover px-20p" label="Versturen" @click="sendComment"/>
          </div>

        </div>
      </template>

      <template v-slot:footer>
      </template>

    </vl-modal-template>

  </div>
</template>

<script>
import ModalElements from "@/components/Elements/Modal/Elements/ModalElements";
import VlLabel from "@/components/Elements/Form/Elements/VlLabel";
import VlTextfield from '@/components/Elements/Form/Elements/VlTextfield'
import VlButton from '@/components/Elements/VlButton'
import * as APIService from '@/services/ApiService'
import {UploadFilesMixin} from "@/mixins/UploadFiles.mixin";
import VlDatePickerSmall from '@/components/Elements/Form/Elements/VlDatePickerSmall'
import dayjs from "@/dayjs";

export default {
  name: "VlActionDetailModal",
  components: {
    VlModalTemplate: ModalElements.VlModalTemplate,
    VlModalHeader: ModalElements.VlModalHeader,
    VlModalDisplayData: ModalElements.VlModalDisplayData,
    VlModalParticipant: ModalElements.VlModalParticipant,
    VlModalActionAttachment: ModalElements.VlModalActionAttachment,
    VlLabel,
    VlTextfield,
    VlButton,
    VlDatePickerSmall
  },
  mixins: [UploadFilesMixin],
  data() {
    return {
      comment: '',
      isUploading: false,
      showDeadlineUpdate: false,
      newDeadlineValue: null,
      deadline: null
    }
  },
  computed: {
    Attachments() {
      return this.LogEntry.attachments
    },
    LogEntry() {
      return this.$store.state.DossierDetailModule.logEntry
    },
    Action() {
      return this.LogEntry.name
    },
    Comment() {
      return this.LogEntry.comment
    },
    ExecutedAt() {
      if (this.LogEntry.executed_at) {
        return this.LogEntry.executed_at_formatted
      }
      return ''
    },
    ExecutedBy() {
      if (typeof this.LogEntry.executed_by !== 'string') return ''

      const splittedString = this.LogEntry.executed_by.split('<>')

      if (splittedString.length > 1) {
        splittedString[1] = `<span class="text-14p">${splittedString[1]}</span>`
      }
      return splittedString.join('<br>')
    },
    HasDeadline() {
      return !!this.LogEntry.deadline
    },
    Deadline() {
      return this.LogEntry.deadline_formatted
    },
    CommentingParticipants() {
      return this.LogEntry.commenting_participants
    }

  },
  methods: {
    removeComment(participant) {
      APIService
          .deleteRemark(participant.id)
          .then(() => {
          })
    },
    saveNewDeadline() {
      const deadline = dayjs(this.deadline).utc(true).local().toISOString()
      APIService.updateActionDeadline(deadline, this.LogEntry.id)
          .then(() => {
            this.$store.dispatch('DossierDetailModule/updateLogActionDeadline', deadline)

            this.$store.dispatch('DossierDetailModule/updateActionDeadline', {
              id: this.LogEntry.id,
              deadline: this.LogEntry.deadline
            })
                .then(() => this.showDeadlineUpdate = false)
          })
    },
    sendComment() {
      if (this.comment.length !== 0) {
        APIService
            .postRemarkToAction(this.comment, this.LogEntry.id).then((response) => {
          this.$store.dispatch('DossierDetailModule/addRemarkToAction', response.data)
          this.comment = ''
        })
      }
    },
    addFiles(event) {
      if (!this.isUploading) {
        const fileList = event.target.files;
        if (fileList.length === 0) return

        const files = this.getFilesFromList(fileList)
        const promiseArray = this.createPromiseArray(files, this.$store.state.DossierDetailModule.logEntry.id)

        this.isUploading = true
        Promise
            .all(promiseArray)
            .then(() => {
              APIService.getActionDetail(this.$store.state.DossierDetailModule.logEntry.id)
                  .then(response => {
                    this.$store.dispatch('DossierDetailModule/updateActionAttachments', response.data.attachments)
                    this.isUploading = false
                  })

              event.target.value = ''
            })
      }


    },
  },
  mounted() {
    this.deadline = this.HasDeadline ? this.$store.state.DossierDetailModule.logEntry.deadline : new Date()
  }
}
</script>

<style scoped>

</style>
