<template>
  <div class="logbook-element" @click="showDetails">
    <vl-index-dossier-card-element
        label="Deadline" :content="Deadline | nonEmpty"
        :flex="true"/>
    <vl-index-dossier-card-element
        label="Actie" :content="Action | nonEmpty"
        :flex="true"/>
    <vl-index-dossier-card-element
        label="Uitvoerder"
        :content="''"
        :flex="true">
      <div class="text-14p select-none break-all text-right lg:text-left">
        {{ action.executed_by | nonEmpty }} <br>
        <span class="text-14p text-gray-light">
        {{ action.executed_location }}
      </span>
      </div>

    </vl-index-dossier-card-element>
    <vl-index-dossier-card-element
        label="Opmerkingen"
        :content="remarkConverted"
        :flex="true"/>
    <div class="hidden lg:block place-self-center"><span class="text-blue-light"><font-awesome-icon
        icon="chevron-right"/></span></div>

    <div class="selected" v-show="selected"/>
  </div>
</template>

<script>
import VlIndexDossierCardElement from "@/components/Elements/VlIndexDossierCardElement";

export default {
  name: "VlLogTableData",
  components: {
    VlIndexDossierCardElement,
  },
  props: {
    action: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },


  computed: {
    Deadline() {
      if (!this.action.deadline) return null
      return this.action.deadline_formatted
    },
    Action() {
      return this.action.name;
    },
    ExecutionDate() {
      if (!this.action.executed_at) return null
      return this.action.executed_at_formatted
    },
    remarkConverted() {
      return this.action.comment.slice(0, 20) + (this.action.comment.length > 20 ? "..." : "")
    }
  },
  methods: {
    showDetails() {
      this.$store.dispatch('DossierDetailModule/setLogEntry', this.action)
    }
  }
}
</script>

<style lang="scss" scoped>
.logbook-element {
  @apply border-b;
  @apply border-solid;
  @apply border-gray-border-30;
  @apply pb-20p;
  @apply mb-20p;
  @apply relative;

  @screen lg {
    @apply px-2;
    @apply grid;
    @apply grid-cols-logbook-grid;
    @apply gap-x-8p;
    @apply transition;
    @apply duration-150;

    @apply pb-0;
    @apply py-8p;
    @apply mb-0;
    @apply border-none;

    &:nth-child(odd) {
      @apply bg-gray-light-10;
    }

    &:hover {
      @apply bg-gray-light-30;
    }
  }
}

.logbook-element .selected {
  @apply bg-blue-dark;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  width: 5px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  @screen lg {
    left: 0;
  }
}
</style>

