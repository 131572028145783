<template>
  <div class="measure-item border-b border-solid border-gray-border-30 mt-8p">
    <div><span class="text-12p text-blue-dark">{{ chapter }}</span> <span class="text-12p text-blue-dark">{{ code }}</span></div>
    <div class="text-14p mb-8p">{{ description}}
    </div>
  </div>
</template>

<script>
export default {
  name: "VlMeasuresItem",
  props: {
    chapter: {
      type:String,
      required:true,
      default: ''
    },
    code: {
      type:String,
      required:true,
      default: ''
    },
    description: {
      type:String,
      required:true,
      default: ''
    }
  }
}
</script>

<style>
.measure-item:last-of-type{
  @apply border-none;
}
</style>
