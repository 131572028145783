<template>
  <div>
    <vl-card>
      <div class="flex flex-wrap justify-between mb-4">
        <vl-card-title title="Logboek"/>
        <div class="h-full flex">
          <button @click="$store.dispatch('DossierDetailModule/setDisplayGenerateTemplate', true)"
                  class="rounded-4p cursor-pointer bg-blue-light hover:bg-blue-light-hover text-white text-14p py-2 px-3 mr-2 grid grid-cols-min-1fr gap-8p">
            <span class="inline-block w-18p place-self-center">
              <img :src="require('@/assets/images/view.svg')" alt=""></span> <span
              class="whitespace-nowrap place-self-center">Bekijk Template</span>
          </button>

          <button
              class="rounded-4p cursor-pointer bg-blue-light hover:bg-blue-light-hover text-white py-2 px-3 text-14p mr-2"
              @click="$store.dispatch('DossierDetailModule/setDisplayAddAction', true)"
              v-if="!$store.getters['DossierDetailModule/isArchived']"
          >
            <font-awesome-icon icon="plus"/>
          </button>


          <button
              class="rounded-4p cursor-pointer bg-blue-light hover:bg-blue-light-hover text-white py-4p px-20p text-14p mr-2 grid grid-cols-min-1fr gap-8p"
              @click="dearchive"
              v-if="$store.getters['DossierDetailModule/isArchived']"
          >
            <span class="inline-block mr-4p text-white text-16p place-self-center" v-show="!isArchiving">
            <font-awesome-icon icon="archive"/>
            </span>

            <span class="inline-block mr-4p text-white text-16p place-self-center" v-show="isArchiving">
            <font-awesome-icon icon="circle-notch" class="fa-spin"/>
            </span>
            <span
                class="place-self-center"> {{ isArchiving ? 'Uit het archief aan het halen' : 'Uit archief halen' }}</span>
          </button>
        </div>
      </div>


      <div class="hidden lg:block">
        <VlLogtableHeader class="w-full"/>
      </div>

      <div v-if="HasLogbookItems">
        <VlLogTableData v-for="(entry, index) in LogbookItems" :key="`logbook_data_entry_${entry.id}`" :action="entry"
                        :selected="index === 0"/>
      </div>

      <div v-if="!HasLogbookItems">
        <div class="text-14p text-blue-light text-center">Er zijn geen logboekvermeldingen beschikbaar</div>
      </div>
    </vl-card>

  </div>
</template>

<script>
import VlCard from "@/components/Elements/Card/VlCard";
import VlCardTitle from "@/components/Elements/Card/VlCardTitle";
import VlLogtableHeader from "@/components/Details/Elements/VlLogTableHeader";
import VlLogTableData from "@/components/Details/Elements/VlLogTableData";
import * as APIService from '@/services/ApiService'
import CreateAction from "@/services/models/CreateAction";

export default {
  name: "VlLog",
  components: {
    VlCard,
    VlCardTitle,
    VlLogtableHeader,
    VlLogTableData
  },
  data() {
    return {
      isArchiving: false
    }
  },
  computed: {
    HasLogbookItems() {
      return this.LogbookItems.length > 0
    },
    LogbookItems() {
      return this.$store.getters['DossierDetailModule/getDossierLogbookItems']
    }
  },
  methods: {
    dearchive() {
      this.isArchiving = true
      const createAction = new CreateAction({
        action_id: this.$store.getters['DataModule/getDearchiveActionID'],
        executed_at: new Date(),
        deadline: null,
        comment: ''
      })

      APIService
          .postLogbookItem(createAction.preparePost(), this.$store.state.DossierDetailModule.dossier_id)
          .then(() => {
            this.$store.dispatch('DossierDetailModule/fetchDossierData')
            this.isArchiving = false
          })
    }
  }
}
</script>

<style scoped>

</style>
