<template>
  <div class="mb-20p">

    <div class="container">
      <section class="my-20p">
        <div class="flex flex-wrap justify-between">
          <div>
            <router-link class="cursor-pointer text-blue-dark flex flex-row items-center" :to="{name: 'index'}">
              <font-awesome-icon icon="chevron-left" class="pr-2 text-xl"/>
              Mijn dossiers
            </router-link>
            <p class="text-blue-dark text-22p lg:text-24p select-none">{{$store.getters['DossierDetailModule/getDossierAddress']}}</p>
            <div class="grid gap-8p" style="grid-template-columns: repeat(4, min-content)">
              <div class="self-center whitespace-nowrap">
                <span class="text-gray-light text-14p mb-6">{{ $store.getters['DossierDetailModule/getCurrentDossier'] }} |</span>
              </div>
              <div class="self-center whitespace-nowrap">
                <span class="text-gray-light text-14p mb-6">{{ ExaminationType }} |</span>
              </div>

              <div class="self-center grid grid-cols-min-min gap-4p">
                <div class="mr-4p pt-2p inline-block place-self-center w-16p">
                  <span class="inline-block circle-left" :class="LeftCircleStyle"/>
                  <span class="inline-block circle-right" :class="RightCircleStyle"/>
                </div>
                <span class="text-gray-light place-self-center text-14p">
                {{ ExaminationResult}}
              </span>
              </div>

              <div class="self-center whitespace-nowrap">
                <span class="text-gray-light text-14p mb-6" v-if="$store.getters['DossierDetailModule/getDossierPreviousExaminationsKPR']">| Voorgaande keuringen: {{ $store.getters['DossierDetailModule/getDossierPreviousExaminationsKPR'] }}</span>
              </div>
            </div>

          </div>
          <div class="w-full mb-4 lg:w-1/5" v-show="$store.getters['DossierDetailModule/hasNewExaminations']">
            <vl-notification text="Er zijn nieuwe keuringen voor dit dossier beschikbaar." icon="info-circle"/>
          </div>
        </div>
      </section>
      <!--    mobile-->
      <section class="grid gap-20p lg:hidden">
        <vl-contact-data/>
        <vl-log/>
        <vl-measures/>
        <vl-poi/>
        <vl-new-examinations/>
        <vl-previous-examinations/>
        <vl-examination-documents/>
        <vl-internal-documents/>
      </section>

      <!--    ipad-->
      <section class="gap-20p hidden lg:grid 3xl:hidden" style="grid-template-columns: 2fr 1fr">
        <div class="flex flex-col space-y-20p">
          <vl-log/>
          <vl-new-examinations/>
          <vl-previous-examinations/>
        </div>
        <div class="flex flex-col space-y-20p">
          <vl-contact-data/>
          <vl-measures/>
          <vl-poi/>
          <vl-examination-documents/>
          <vl-internal-documents/>
        </div>
      </section>

      <!--  larger-->
      <section class="gap-20p hidden 3xl:grid" style="grid-template-columns: 1fr 2fr 1fr">
        <div class="flex flex-col space-y-20p">
          <vl-contact-data/>
          <vl-examination-documents/>
          <vl-internal-documents/>
        </div>
        <div class="flex flex-col space-y-20p">
          <vl-log/>
          <vl-new-examinations/>
          <vl-previous-examinations/>
        </div>
        <div class="flex flex-col space-y-20p">
          <vl-measures/>
          <vl-poi/>
        </div>
      </section>
    </div>






    <!-- Modals -->
    <vl-fade-animation>
      <div v-if="$store.state.DossierDetailModule.displayLogEntry">
        <vl-action-detail-modal/>
      </div>

    </vl-fade-animation>

    <vl-fade-animation>
      <div v-if="$store.state.DossierDetailModule.displayGenerateTemplate">
        <vl-generate-template-modal />
      </div>
    </vl-fade-animation>

    <vl-fade-animation>
      <div v-if="$store.state.DossierDetailModule.displayAddAction">
        <vl-add-action-modal />
      </div>
    </vl-fade-animation>

    <vl-fade-animation>
      <div v-if="$store.state.DossierDetailModule.DossierDetailInspectionModule.displayInspection">
        <vl-show-inspection-modal />
      </div>
    </vl-fade-animation>

  </div>
</template>

<script>
import VlContactData from "@/components/Details/VlContactData";
import VlExaminationDocuments from "@/components/Details/VlExaminationDocuments";
import VlInternalDocuments from "@/components/Details/VlInternalDocuments";
import VlPreviousExaminations from "@/components/Details/VlPreviousExaminations";
import VlNewExaminations from "@/components/Details/VlNewExaminations";
import VlMeasures from "@/components/Details/VlMeasures";
import VlPoi from "@/components/Details/VlPoi";
import VlLog from "@/components/Details/VlLog";
import VlNotification from '@/components/Elements/VlNotification';
import VlActionDetailModal from "@/components/Elements/Modal/VlActionDetailModal";
import VlFadeAnimation from "@/components/Animations/VlFadeAnimation";
import VlGenerateTemplateModal from "@/components/Elements/Modal/VlGenerateTemplateModal";
import VlAddActionModal from "@/components/Elements/Modal/VlAddActionModal";
import VlShowInspectionModal from "@/components/Elements/Modal/VlShowInspectionModal";

export default {
  name: "Details",
  components: {
    VlContactData,
    VlExaminationDocuments,
    VlInternalDocuments,
    VlPoi,
    VlPreviousExaminations,
    VlNewExaminations,
    VlMeasures,
    VlLog,
    VlNotification,
    VlActionDetailModal,
    VlFadeAnimation,
    VlGenerateTemplateModal,
    VlAddActionModal,
    VlShowInspectionModal
  },
  computed:{
    ExaminationType(){
      return this.$store.state.DossierDetailModule.detail.examination.type
    },
    ExaminationResult(){
      return this.$store.state.DossierDetailModule.detail.examination.result
    },
    LeftCircleStyle() {
      if ( this.ExaminationResult === 'NC' || this.ExaminationResult === 'NCO') return 'bg-red'
      if (this.ExaminationResult === 'CO' || this.ExaminationResult === 'C') return 'bg-green'
      return ''
    },
    RightCircleStyle() {
      if (this.ExaminationResult === 'NC') return 'bg-red'
      if (this.ExaminationResult === 'NCO' || this.ExaminationResult === 'CO') return 'bg-orange'
      if (this.ExaminationResult === 'C') return 'bg-green'
      return ''
    }
  },
  created() {
    this.$store.dispatch('setLoaded', false)
    this.$store.dispatch('DossierDetailModule/setDosssierId', this.$route.params.id)
        .then(() => {
          this.$store.dispatch('DossierDetailModule/fetchDossierData')
        })

  }
}
</script>

<style scoped>

</style>
