<template>
  <div>
    <vl-card>
      <vl-card-title title="Herstelmaatregelen" :amount="Measures.length"></vl-card-title>
      <section v-show="showData">
        <vl-measures-item :key="`measure_item_${index}`" :description="measure.description" :chapter="measure.chapter" :code="measure.code" v-for="(measure,index) in Measures"/>


        <div v-if="Measures.length === 0 && !isOldDossier"><vl-not-available-notification message="Er zijn geen herstelmaatregelingen beschikbaar"/></div>
        <div v-if="Measures.length === 0 && isOldDossier">
          <vl-not-available-notification message="Zie keuringsattest"/>
        </div>
      </section>
    </vl-card>
  </div>
</template>

<script>
import VlCard from "@/components/Elements/Card/VlCard";
import VlMeasuresItem from "@/components/Elements/VlMeasuresItem";
import VlCardTitle from "@/components/Elements/Card/VlCardTitle";
import VlNotAvailableNotification from '@/components/Elements/VlNotAvailableNotification'

export default {
  name: "VlMeasures",
  components: {
    VlCard,
    VlMeasuresItem,
    VlCardTitle,
    VlNotAvailableNotification
  },
  data(){
    return {
      showData: true
    }
  },
  computed:{
    isOldDossier(){
      return this.$store.getters['DossierDetailModule/isAOldDossier']
    },
    Measures() {
      return this.$store.getters['DossierDetailModule/getDossierMeasures']
    }
  }
}
</script>

<style scoped>

</style>
