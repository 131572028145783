<template>
  <div>
    <vl-card>
      <vl-card-title title="Keuringsdocumenten" :amount="Attachments.length"/>
      <section v-if="HasAttachments">

        <div :key="`attachment_${attachment.id}`" v-for="attachment in Attachments">
          <vl-file
                   :filename="attachment.document"
                   :filetype="attachment.extension"
                   :download_url="attachment.url"/>
        </div>

      </section>

      <section v-if="!HasAttachments">
        <vl-not-available-notification message="Er zijn geen keuringsdocumenten beschikbaar" />
      </section>
    </vl-card>
  </div>
</template>

<script>
import VlCard from "@/components/Elements/Card/VlCard";
import VlCardTitle from "@/components/Elements/Card/VlCardTitle";

import VlFile from '@/components/Elements/VlFile';
import VlNotAvailableNotification from "@/components/Elements/VlNotAvailableNotification";
export default {
  name: "VlExaminationDocuments",
  components: {
    VlCard,
    VlFile,
    VlCardTitle,
    VlNotAvailableNotification

  },
  computed: {
    HasAttachments() {
      return this.Attachments.length > 0
    },
    Attachments() {
      return this.$store.getters['DossierDetailModule/getDossierInternalDocuments']
    }
  },
}
</script>

<style scoped>

</style>
