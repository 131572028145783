<template>
  <div class="p-18p rounded-4p bg-white shadow-card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VlCard"
}
</script>

<style scoped>

</style>
