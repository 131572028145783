<template>
  <div>
    <vl-card>
      <vl-card-title title="Contact gegevens"/>
      <section v-show="showData">
        <vl-contact-data-element :name="Owner.name" title="Eigenaar" :phone="Owner.phone | nonEmpty"/>
        <vl-contact-data-element :name="Customer.name" title="Opdrachtgever" :phone="Customer.phone | nonEmpty"/>
      </section>

    </vl-card>
  </div>
</template>

<script>
import VlCard from "@/components/Elements/Card/VlCard";
import VlContactDataElement from "@/components/Elements/VlContactDataElement";
import VlCardTitle from "@/components/Elements/Card/VlCardTitle";

export default {
  name: "VlContactData",
  components: {
    VlCard,
    VlContactDataElement,
    VlCardTitle
  },
  data(){
    return{
      showData: true
    }
  },
  computed:{
    Owner(){
      return this.$store.getters['DossierDetailModule/getDossierOwner']
    },
    Customer(){
      return this.$store.getters['DossierDetailModule/getDossierCustomer']
    }
  }
}
</script>

<style scoped>

</style>
