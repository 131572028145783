<template>
  <div class="px-2 grid bg-gray-light-20 py-8p mb-12p rounded-4p" style="grid-template-columns: 150px 150px 1fr  50px;">
    <div class="self-center flex">
      <span class="text-blue-light text-12p whitespace-nowrap select-none">
        KPR nummer
      </span>
    </div>
    <div class="self-center flex">
      <span class="text-blue-light text-12p whitespace-nowrap select-none">
        Resultaat keuring
      </span>
    </div>
    <div class="self-center flex">
      <span class="text-blue-light text-12p whitespace-nowrap select-none">
        Type keuring
      </span>
    </div>
    <div class="self-center flex">
      <span class="text-blue-light text-12p whitespace-nowrap select-none"></span>
    </div>
    <div class="self-center"></div>
  </div>
</template>

<script>
export default {
  name: "VlExaminationTableHeader",
}
</script>
