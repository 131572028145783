<template>
  <div class="mb-16p">
    <span class="text-blue-dark text-xl font-600">{{ title }}</span> <span class="text-gray-light text-sm" v-if="amount">({{amount}})</span>
  </div>
</template>

<script>
export default {
  name: "VlCardTitle",
  props: {
    title: {
      type: String,
      required: true
    },
    amount: {
      type: [String, Number],
      required: false,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
