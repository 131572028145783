<template>
  <div>
    <div><span class="text-12p text-gray-light">{{ title }}</span></div>
    <div>{{ name }}</div>
    <div><a href="" class="text-blue-dark text-12p">{{ phone }}</a></div>
  </div>
</template>

<script>
export default {
  name: "VlContactDataElement",
  props: {
    title: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
